import { React, useState, useEffect } from "react";
import Swal from "sweetalert2";
import api from "../../Utils/api";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import {
  getCurrentDateTime,
  formatDate,
  addDecimalPlaces,
} from "../../Utils/commonfunctions";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

const sumAggFunc = (params) => {
  const values = params.values;
  let sum = 0;

  values.forEach((value) => {
    sum += value;
  });

  return sum;
};

const pinnedBottomRowDataIssue = [
  {
    createdDate: null,
    departmentName: "",
    createdUserDepartmentName: "",
    batchNo: "Total",
    pcs: 0,
    height: 0,
    weight: 0,
  },
];

const pinnedBottomRowDataReceive = [
  {
    createdDate: null,
    departmentName: "",
    createdUserDepartmentName: "",
    batchNo: "Total",
    pcs: 0,
    height: 0,
    weight: 0,
  },
];

const IssueAndReceiveReport = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [departmentID, setDepartmentID] = useState(0);
  const [toDepartmentID, setToDepartmentID] = useState(0);
  const [departmentOption, setDepartmentOption] = useState([]);
  const [toDepartmentOption, setToDepartmentOption] = useState([]);

  const [issueToDepartmentData, setIssueToDepartmentData] = useState([]);
  const [receiveToDepartmentData, setReceiveToDepartmentData] = useState([]);

  const [fromUserDropdown, setFromUserDropdown] = useState([]);
  const [userID, setUserID] = useState(0);

  const [toUserDropdown, setToUserDropdown] = useState([]);
  const [touserID, setToUserID] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [ReportFor, setReportFor] = useState("");

  useEffect(() => {
    setStartDate(getCurrentDateTime);
    setEndDate(getCurrentDateTime);
    getDepartmentName();
    getUserName();
    setIssueToDepartmentData([]);
    setReceiveToDepartmentData([]);
  }, []);

  const getDepartmentName = () => {
    api
      .get("Department/All")
      .then((result) => {
        setDepartmentOption(result.data.data);
        setToDepartmentOption(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserName = () => {
    api
      .get("User/All")
      .then((result) => {
        setFromUserDropdown(result.data.data);
        setToUserDropdown(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ddDepartmentList = (e) => {
    setDepartmentID(e.target.value);
  };

  const ddUserList = (e) => {
    setUserID(e.target.value);
  };

  const ddToUserList = (e) => {
    setToUserID(e.target.value);
  };

  const ddToDepartmentList = (e) => {
    setToDepartmentID(e.target.value);
  };

  const ClearData = () => {
    setStartDate(getCurrentDateTime);
    setEndDate(getCurrentDateTime);
    setIssueToDepartmentData([]);
    setReceiveToDepartmentData([]);
    setDepartmentID(0);
    setToDepartmentID(0);
  };

  const PrintReportData = () => {
    debugger;
    if (ReportFor === "") {
      toast.error("Please select Report for");
      return;
    } else {
      if (ReportFor === "Issue") {
        PrintIssueToDepartmentReport();
      } else if (ReportFor === "Receive") {
        PrintReceiveToDepartmentReport();
      } else {
        return null;
      }
    }
  };

  //Data Start For Issue To Department

  const PrintIssueToDepartmentReport = () => {
    debugger;
    setIsLoading(true); // Show loader

    if (!startDate) {
      Swal.fire("Error", "Please Select Start Date", "error");
      setIsLoading(false);
      return;
    }

    if (!endDate) {
      Swal.fire("Error", "Please Select End Date", "error");
      setIsLoading(false);
      return;
    }

    // Reset the data and loader for each search
    setIssueToDepartmentData([]);
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    api
      .get(
        `Report/PrintIssueToDepartmentReportData?START_DATE=${formattedStartDate}&END_DATE=${formattedEndDate}&DepartmentID=${departmentID}&ToDepartmentID=${toDepartmentID}&CreatedUserID=${userID}&UserID=${touserID}`
      )
      .then((result) => {
        const MachineDataList = result.data.data;
        setIssueToDepartmentData(MachineDataList);
        updatePinnedBottomIssueRowData(null, MachineDataList);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };

  useEffect(() => {
    if (issueToDepartmentData.length > 0) {
      updatePinnedBottomIssueRowData();
    }
  }, [issueToDepartmentData]);

  // export to exel
  const exportToExcel = () => {
    setIsLoading(true);

    setTimeout(async () => {
      try {
        let fileName;
        let formattedData;

        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          });
        };

        if (ReportFor === "Issue") {
          fileName = "IssueReport.xlsx";
          formattedData = issueToDepartmentData.map((item) => {
            return {
              Date: formatDate(item.createdDate),
              FromDept: item.createdUserDepartmentName,
              ToDept: item.departmentName,
              BatchNo: item.batchNo || item.stoneNo,
              Pcs: item.pcs,
              Height: item.height,
              Weight: item.weight,
            };
          });
        } else if (ReportFor === "Receive") {
          fileName = "ReceiveReport.xlsx";
          formattedData = receiveToDepartmentData.map((item) => {
            return {
              Date: formatDate(item.createdDate),
              FromDept: item.createdUserDepartmentName,
              ToDept: item.departmentName,
              BatchNo: item.batchNo || item.stoneNo,
              Pcs: item.pcs,
              Height: item.height,
              Weight: item.weight,
            };
          });
        } else {
          throw new Error("Invalid ReportFor value");
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Machine List");

        // Set up the columns
        worksheet.columns = [
          { header: "Date", key: "Date", width: 30 },
          { header: "From Department", key: "FromDept", width: 20 },
          { header: "To Department", key: "ToDept", width: 20 },
          { header: "Batch No", key: "BatchNo", width: 15 },
          { header: "Pcs", key: "Pcs", width: 10 },
          { header: "Height", key: "Height", width: 15 },
          { header: "Weight", key: "Weight", width: 15 },
        ];

        // Add the rows to the worksheet
        worksheet.addRows(formattedData);

        // Style the header row
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF4F81BD" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });

        // Adjust column widths based on content length
        worksheet.columns.forEach((column) => {
          let maxLength = column.header.length;
          formattedData.forEach((row) => {
            const cellValue = row[column.key];
            if (cellValue) {
              maxLength = Math.max(maxLength, cellValue.toString().length);
            }
          });
          column.width = maxLength + 2; // Add padding
        });

        // Export the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
        // Handle any error here, such as showing an error message to the user
      } finally {
        setIsLoading(false);
      }
    }, 1000);
  };

  const columnDefs = [
    {
      headerName: "Index",
      valueGetter: (params) => {
        if (params.node.rowPinned) {
          return "";
        }
        return params.node.rowIndex + 1;
      },
      cellStyle: { textAlign: "left" },
      flex: 1,
      minWidth: 70,
      filter: true,
      editable: true,
    },
    {
      headerName: "Date",
      field: "createdDate",
      flex: 1,
      minWidth: 100,
      cellStyle: { textAlign: "left" },
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          const formattedDate = date.toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          return formattedDate;
        }
        return "";
      },
    },
    {
      headerName: "From Dept.",
      valueGetter: (params) => {
        const department = params.data?.createdUserDepartmentName || "N/A";
        const createdUserName = params.data?.createdUserName || "Unknown";
        return `${department}: ${createdUserName} `;
      },
      cellStyle: { textAlign: "left" },
      flex: 1,
      minWidth: 100,
      sortable: true,
      filter: true,
      aggFunc: sumAggFunc,
    },
    {
      headerName: "To Dept.",
      valueGetter: (params) => {
        const departmentName = params.data?.departmentName || "N/A";
        const userName = params.data?.userName || "Unknown";
        return `${departmentName} (${userName})`;
      },
      flex: 1,
      minWidth: 100,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Batch No",
      field: "batchNo",
      cellStyle: { textAlign: "left" },
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
      cellRenderer: function (params) {
        if (params.value === null || params.value === undefined) {
          return params.data.stoneNo; // Display stoneNo when batchNo is null
        } else {
          return params.value; // Display batchNo when it's not null
        }
      },
    },
    {
      headerName: "Pcs",
      field: "pcs",
      cellStyle: { textAlign: "left" },
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Height",
      field: "height",
      cellStyle: { textAlign: "left" },
      flex: 1,
      minWidth: 100,
      sortable: true,
      filter: true,
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Weight",
      field: "weight",
      flex: 1,
      minWidth: 100,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const calculateSums = (event, PassData) => {
    var filteredData;
    if (PassData) {
      filteredData = PassData;
    } else if (event) {
      filteredData = event.api.getModel().rowsToDisplay.map((row) => row.data);
    } else {
      filteredData = issueToDepartmentData;
    }

    const filteredSumPcs = _.sumBy(filteredData, "pcs");

    var totalHeight = 0;
    filteredData.forEach((item) => {
      totalHeight += item.pcs * item.height;
    });
    const filteredSumHeight = totalHeight / filteredSumPcs;

    const filteredSumWeight = _.sumBy(filteredData, "weight");

    return {
      footerTotalHeight: addDecimalPlaces(filteredSumHeight, 2),
      footerTotalWeight: addDecimalPlaces(filteredSumWeight, 2),
      footerTotalPcs: filteredSumPcs,
    };
  };

  const updatePinnedBottomIssueRowData = (event, PassData) => {
    const sums = calculateSums(event, PassData);
    pinnedBottomRowDataIssue[0].height = sums.footerTotalHeight;
    pinnedBottomRowDataIssue[0].pcs = sums.footerTotalPcs;
    pinnedBottomRowDataIssue[0].weight = sums.footerTotalWeight;
  };

  const onFilterChangedIssue = (event) => {
    updatePinnedBottomIssueRowData(event);
    if (event) {
      event.api.refreshCells({ force: true, suppressFlash: true });
    }
  };

  //End New Code

  //Data Start For Issue To Department
  const PrintReceiveToDepartmentReport = () => {
    setIsLoading(true); // Show loader
    if (startDate === "" || startDate === null) {
      return Swal.fire("Error", "Please Select Start Date", "error");
    }

    if (endDate === "" || endDate === null) {
      return Swal.fire("Error", "Please Select End Date", "error");
    }
    setReceiveToDepartmentData([]);
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    api
      .get(
        `Report/PrintReceiveToDepartmentReportData?START_DATE=${formattedStartDate}&END_DATE=${formattedEndDate}&DepartmentID=${departmentID}&ToDepartmentID=${toDepartmentID}&CreatedUserID=${userID}&UserID=${touserID}`
      )
      .then((result) => {
        const MachineDataList = result.data.data;
        setReceiveToDepartmentData(MachineDataList);
        updatePinnedBottomReceiveRowData(null, MachineDataList);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loader
      });
  };

  const columnDefsReceive = [
    {
      headerName: "Index",
      valueGetter: (params) => {
        if (params.node.rowPinned) {
          return ""; // Set index to an empty string for the pinned bottom row
        }
        return params.node.rowIndex + 1;
      },
      cellStyle: { textAlign: "left" },
      flex: 1,
      minWidth: 70,
      filter: true,
      editable: true,
    },
    {
      headerName: "Date",
      field: "receivedDate",
      flex: 1,
      minWidth: 100,
      cellStyle: { textAlign: "left" },
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          const formattedDate = date.toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          return formattedDate;
        }
        return "";
      },
    },
    {
      headerName: "From Dept.",
      valueGetter: (params) => {
        const department = params.data?.createdUserDepartmentName || "N/A";
        const createdUserName = params.data?.userName || "Unknown";
        return `${department}: ${createdUserName} `;
      },
      cellStyle: { textAlign: "left" },
      flex: 1,
      minWidth: 100,
      sortable: true,
      filter: true,
      aggFunc: sumAggFunc,
    },
    {
      headerName: "To Dept.",
      valueGetter: (params) => {
        const departmentName = params.data?.departmentName || "N/A";
        const userName = params.data?.userName || "Unknown";
        return `${departmentName} (${userName})`;
      },
      flex: 1,
      minWidth: 100,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Batch No",
      field: "batchNo",
      flex: 1,
      minWidth: 100,
      cellStyle: { textAlign: "left" },
      sortable: true,
      filter: true,
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
      cellRenderer: function (params) {
        if (params.value === null || params.value === undefined) {
          return params.data.stoneNo; // Display stoneNo when batchNo is null
        } else {
          return params.value; // Display batchNo when it's not null
        }
      },
    },
    {
      headerName: "Pcs",
      field: "pcs",
      flex: 1,
      minWidth: 100,
      cellStyle: { textAlign: "left" },
      sortable: true,
      filter: true,
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Height",
      field: "height",
      flex: 1,
      minWidth: 100,
      cellStyle: { textAlign: "left" },
      sortable: true,
      filter: true,
      aggFunc: sumAggFunc, // Use the sumAggFunc for aggregation
    },
    {
      headerName: "Weight",
      field: "weight",
      flex: 1,
      minWidth: 100,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  useEffect(() => {
    updatePinnedBottomReceiveRowData();
  }, [receiveToDepartmentData]);

  const calculateSumsReceive = (event, PassData) => {
    var filteredData;
    if (PassData) {
      filteredData = PassData;
    } else if (event === undefined) {
      filteredData = receiveToDepartmentData;
    } else {
      filteredData = event.api.getModel().rowsToDisplay.map((row) => row.data);
    }

    const filteredSumPcs = _.sumBy(filteredData, "pcs");

    var totalHeight = 0;
    filteredData.forEach((item) => {
      totalHeight += item.pcs * item.height;
    });
    const filteredSumHeight = totalHeight / filteredSumPcs;

    const filteredSumWeight = _.sumBy(filteredData, "weight");

    return {
      footerTotalHeightReceive: addDecimalPlaces(filteredSumHeight, 2),
      footerTotalWeightReceive: addDecimalPlaces(filteredSumWeight, 2),
      footerTotalPcsReceive: filteredSumPcs,
    };
  };

  const updatePinnedBottomReceiveRowData = (event, PassData) => {
    const sums = calculateSumsReceive(event, PassData);
    pinnedBottomRowDataReceive[0].height = sums.footerTotalHeightReceive;
    pinnedBottomRowDataReceive[0].pcs = sums.footerTotalWeightReceive;
    pinnedBottomRowDataReceive[0].weight = sums.footerTotalPcsReceive;
  };

  const onFilterChangedReceive = (event) => {
    updatePinnedBottomReceiveRowData(event);
    if (event) {
      event.api.refreshCells({ force: true, suppressFlash: true });
    }
  };

  //End New Code

  return (
    <>
      <ToastContainer />
      <div className="card">
        <div className="card-header">
          <span>Issue & Receive Report</span>
        </div>
        <div className="card-body">
          <div
            className="row col-md-12 mb-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="machineStartDate">Report For:</strong>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setReportFor(e.target.value);
                  console.log(e.target.value);
                }}
              >
                <option>Select Report For</option>
                <option value="Issue">Issue</option>
                <option value="Receive">Receive </option>
              </Form.Select>
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="machineStartDate">Start Date:</strong>
              <input
                type="datetime-local"
                className="form-control"
                id="machineStartDate"
                placeholder="Enter Machine Start Time"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="machineEndDate">End Date:</strong>
              <input
                type="datetime-local"
                className="form-control"
                id="machineEndDate"
                placeholder="Enter Machine Start Time"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="departmentID">From Department Name:</strong>
              <div className="input-group">
                <select
                  className="form-control"
                  name="departmentID"
                  value={departmentID}
                  onChange={ddDepartmentList}
                >
                  <option value="">--Select From Dept.--</option>
                  {departmentOption.map((option) => (
                    <option
                      key={option.departmentID}
                      value={option.departmentID}
                    >
                      {option.departmentName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="toDepartmentID">To Department Name:</strong>
              <div className="input-group">
                <select
                  className="form-control"
                  name="toDepartmentID"
                  value={toDepartmentID}
                  onChange={ddToDepartmentList}
                >
                  <option value="">--Select To Dept.--</option>
                  {toDepartmentOption.map((option) => (
                    <option
                      key={option.departmentID}
                      value={option.departmentID}
                    >
                      {option.departmentName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <br />
              <button
                type="submit"
                className="btn btn-primary"
                onClick={PrintReportData}
              >
                <i class="fa fa-search"></i>
                {"  "}
                Search
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={ClearData}
                style={{ marginLeft: "10px" }}
              >
                <i class="fa fa-refresh"></i>
                {"  "}
                Clear
              </button>
            </div>
          </div>
          <div className="row col-md-12 mb-3" style={{ display: "flex" }}>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="userID">From User:</strong>
              <div className="input-group">
                <select
                  className="form-control"
                  name="userID"
                  value={userID}
                  onChange={ddUserList}
                >
                  <option value="">--From User.--</option>
                  {fromUserDropdown.map((option) => (
                    <option key={option.userID} value={option.userID}>
                      {option.userName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-2 col-sm-2 col-lg-2">
              <strong htmlFor="touserID">To User:</strong>
              <div className="input-group">
                <select
                  className="form-control"
                  name="touserID"
                  value={touserID}
                  onChange={ddToUserList}
                >
                  <option value="">--To User.--</option>
                  {toUserDropdown.map((option) => (
                    <option key={option.userID} value={option.userID}>
                      {option.userName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        {isLoading && (
          <>
            <div className="overlay"></div>
            <div className="overlay-content text-center">
              <Spinner animation="" variant="primary">
                <span class="loader"></span>
              </Spinner>
            </div>
          </>
        )}

        <div className="card-header d-flex justify-content-between">
          <span>
            {ReportFor === "Issue" && "Issue From Department"}
            {ReportFor === "Receive" && "Receive From Department"}
          </span>
          {ReportFor !== "" && (
            <Button
              type="button"
              variant="success"
              className="btn btn-primary mb-2"
              onClick={exportToExcel}
            >
              <i className="fa fa-file-excel"></i> Export to Excel
            </Button>
          )}
        </div>
        <div className="card-body">
          <>
            {ReportFor === "Issue" && (
              <AgGridComponent
                columnDefs={columnDefs}
                rowData={issueToDepartmentData}
                onFilterChanged={onFilterChangedIssue}
                pinnedBottomRowData={pinnedBottomRowDataIssue}
                paginationPageSize={100}
                height="75vh"
              />
            )}
            {ReportFor === "Receive" && (
              <AgGridComponent
                columnDefs={columnDefsReceive}
                rowData={receiveToDepartmentData}
                onFilterChanged={onFilterChangedReceive}
                pinnedBottomRowData={pinnedBottomRowDataReceive}
                paginationPageSize={100}
                height="75vh"
              />
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default IssueAndReceiveReport;
